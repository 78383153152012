<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">游客管理月度记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="年份" prop="NF" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.NF"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="月份" prop="YF" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.YF"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in yfenumData"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <div class="ykstyle">游客信息</div>
                <el-form-item
                    prop="YKZRCS"
                    label="游客总人次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YKZRCS"
                        @input="toNumber($event, 'YKZRCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    prop="YYYKRCS"
                    label="预约游客人次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YYYKRCS"
                        @input="toNumber($event, 'YYYKRCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    prop="GWYKRCS"
                    label="国外游客人次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.GWYKRCS"
                        @input="toNumber($event, 'GWYKRCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <div class="ykstyle">导游信息</div>
                <el-form-item
                    prop="JJYJJCS"
                    label="讲解员讲解次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JJYJJCS"
                        @input="toNumber($event, 'JJYJJCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    prop="WBDYCS"
                    label="外部导游次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WBDYCS"
                        @input="toNumber($event, 'WBDYCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SPXDWBDYCS"
                    label="受培训的外部导游次数"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SPXDWBDYCS"
                        @input="toNumber($event, 'SPXDWBDYCS')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ykglydjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1102",
                dataDic: {
                    glycbtid: this.heritageId,
                    NF: "",
                    YF: "",
                    YKZRCS: "",
                    YYYKRCS: "",
                    GWYKRCS: "",
                    JJYJJCS: "",
                    WBDYCS: "",
                    SPXDWBDYCS: "",
                },
            },
            rules: {
                NF: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
                YF: [
                    {
                        required: true,
                        message: "请选择月份",
                        trigger: "change",
                    },
                ],
            },
            enumData: [],
            yfenumData: [],
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1102",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "PG") {
                    this.PGList = v.dataItems;
                } else if (v.COLUMNID === "YF") {
                    this.yfenumData = v.dataItems;
                }
            });
        },
        GetDataNext() {
            if (this.formdata.dataDic.NF) {
                this.formdata.dataDic.NF = "" + this.formdata.dataDic.NF;
            }
            if (this.formdata.dataDic.YF || this.formdata.dataDic.YF === 0) {
                this.formdata.dataDic.YF = "" + this.formdata.dataDic.YF;
            }
        },
    },
};
</script>

<style>
.ykstyle {
    background-color: #c2d3e2;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #48576a;
    line-height: 2;
    text-indent: 15px;
    width: 100%;
}
</style>
